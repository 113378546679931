import http from "../utils/http";
import Cookies from "js-cookie"
let resquest = "/api";
let resHead = function(){
  return (Cookies.get('isHxhc') == 1 ? 'hxhc' : 'orderH5')
} 



export function login (params) {
  return http.get(`${resquest}/a/login`, params);
}

export function getShopH5 (params) {
  return http.get(`${resquest}/shopH5/index`, params)
}

export function getDiscount (params) {
  let head = resHead()
  return http.get(`${resquest}/${head}/discount`, params)
}

export function getSaoma (params) {
  return http.get(`${resquest}/hxhc/saoma`, params)
}

//商户联盟返现统计
export function lmReturnCount (params) {
  return http.get(`${resquest}/shopH5/lmReturnCount`, params)
}

export function getRole (params) {
  return http.get(`${resquest}/hxhc/role`, params)
}

export function getHxhc (params) {
  return http.get(`${resquest}/hxhc/index`, params)
}

export function getPreferList (params, url) {
  return http.get(`${resquest}${url}`, params)
}

export function getConfigs (params) {
  return http.get(`${resquest}/farm/hx/configs?signUrl=${params}`)
}
// 关闭订单
export function closeOrder ({ params }) {
  let head = resHead()
  return http.get(`${resquest}/${head}/closeOrder`, params)
}
// 一键核销
export function writeOff (params) {
  let head = resHead()
  return http.post(`${resquest}/${head}/writeOff`, params)
}

// 扫码核销
export function writeOffsm (params) {
  return http.get(`${resquest}/farm/hx?detailId=${params}`)
}

//获取商户信息
export function getUserInfo () {
  return http.get(`${resquest}/farm/hx/userInfo`)
}

//获取核销记录
export function getVeriList (params) {
  return http.get(`${resquest}/farm/hx/hxPage`, params)
}
// 导入axios
import axios from "axios";
import Cookies from "js-cookie";
import { Toast } from "vant";
import router from '@/router/index.js'
//1. 创建新的axios实例，
const service = axios.create({
  baseURL: "/",
  timeout: 10 * 1000
});
// 2.请求拦截器
service.interceptors.request.use(
  config => {
    if (config.url !== "/api/orderH5/list")
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0
      });
    // console.log(config)
    config.data = JSON.stringify(config.data);
    if (config.method == "put") {
      config.data = JSON.stringify(config.params);
    }
    const token = Cookies.get("token");
    if (config.url.indexOf('szxcNhUserXxCoupon') > -1) {
      config.params = { ...config.params };
    } else {
      config.params = { ...config.params, _sid: token, __ajax: "json" };
    }

    config.headers = {
      "Content-Type": "application/json"
      // "__ajax": "json"
    };

    // if (token) {
    //   config.headers.Authorization = token;
    // }
    return config;
  },
  error => {
    Toast.clear();
    Promise.reject(error);
  }
);

// 3.响应拦截器
service.interceptors.response.use(
  response => {
    Toast.clear();
    if (response.data.result == "login" || response.data.code == 401 || response.data.result?.code == 401) {
      Toast.fail("身份失效！请重新登录");
      Cookies.remove('token')
      router.replace("/login")
      return
    }
    const ruleList = ['/api/a/login']
    if (ruleList.indexOf(response.config.url) > -1) {
      return response.data;
    }
    if (response.data && response.data.code != 200) {
      return response.data
    }
    return response.data;
  },
  error => {
    Toast.clear();
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.message = "错误请求";
          break;
        case 401:
          {
            error.message = "未授权，请重新登录";
            Cookies.remove('token')
            router.replace("/login")
            break;
          }
        case 403:
          error.message = "拒绝访问";
          break;
        case 404:
          error.message = "请求错误,未找到该资源";
          // window.location.href = "/NotFound";
          break;
        case 405:
          error.message = "请求方法未允许";
          break;
        case 408:
          error.message = "请求超时";
          break;
        case 500:
          error.message = "服务器端出错";
          break;
        case 501:
          error.message = "网络未实现";
          break;
        case 502:
          error.message = "网络错误";
          break;
        case 503:
          error.message = "服务不可用";
          break;
        case 504:
          error.message = "网络超时";
          break;
        case 505:
          error.message = "http版本不支持该请求";
          break;
        default:
          error.message = `连接错误${error.response.status}`;
      }
    } else {
      // 超时处理
      if (JSON.stringify(error).includes("timeout")) {
        Toast("服务器响应超时，请刷新当前页");
      }
      error.message("连接服务器失败");
    }
    Toast(error.message);
    return Promise.resolve(error.response.data);
  }
);
//4.导入文件
export default service;
